@media screen and (max-width: 1000px) {
	/*#banner-site {
		margin-bottom: 40px;
	}*/

	#pacotes {
		margin-bottom: 40px;
	}

	#acomodacoes {
		img {
			width: 100%;
		}

		.textos-ocultos-img .itens-alinha {
			width: 100%;
			padding: 0;
		}
	}

	#gastronomia .gastro-lista {
		margin-top: 0;
	}

	#video-golfe {
		.fleft {
			width: 100%;
		}

		.texto-golfe {
			box-sizing: border-box;
			padding: 20px;
			border-radius: 0;
		}
	}

	.texto-gastronomia {
		.wrapper-600 {
			max-width: 100%;
			width: 100%;
		}

		.texto-interna {
			box-sizing: border-box;
			float: none;
			left: 0;
			padding: 20px;
			border-radius: 0;
			@include clearfix;
		}
	}

	#lazer {
		padding-bottom: 0;

		.titulo-secao {
			position: static;
			float: none;
			margin-bottom: 30px !important;
		}

		.myloader {
			position: static;
			margin: 0 auto;
		}
	}

	.allinone_carousel {
		margin-top: 0;
	}

	#centro-eventos {
		margin-top: 40px;

		.titulo-secao {
			position: static;
			float: none;
		}

		.fright {
			position: static;
			float: none;
			width: 100%;
		}

		.centro-eventos-texto {
			position: static;
			margin: 0;
			border-radius: 0;

			p {
				box-sizing: border-box;
				position: static;
				float: none;
				width: 100% !important;
				padding: 20px !important;
				margin: 0;
			}
		}
	}

	#receba-novidades {
		margin-top: 40px;
	}
}
