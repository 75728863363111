// Colors default

$color-light:					#ffffff;
$color-dark:					#000000;

$color-text:            		#1c3765;

// Colors pallete

$color-pallete-1: 				#009fe3;
$color-pallete-2: 				#ad927c;
$color-pallete-3:				#ede9e8;
$color-pallete-4:				#ede9e8;
$color-pallete-5:				#282828;

// Color Elements

$color-anchor:					$color-pallete-2;

$color-tab-item:				#1c3765;

$color-form-control-border:		#1c3765;

$color-disabled:				#e6e6e6;

// Bootstrap (States)

$color-state-default:			#ffffff;
$color-state-primary:			#06a7e2;
$color-state-info:				#ad927c;
$color-state-danger:			#ec3237;
$color-state-success:			#2ecc71;
$color-state-warning:			#eb7629;

$color-state-outline-info:		#ede9e8;

// Social Media

$color-social-behance: 			#1769ff;
$color-social-dropbox: 			#007ee5;
$color-social-facebook:			#3b5998;
$color-social-github:  			#333333;
$color-social-google:			#dd4b39;
$color-social-instagram:		#125688;
$color-social-lastfm: 			#d51007;
$color-social-linkedin: 		#007bb5;
$color-social-mailchimp:		#449a88;
$color-social-pinterest:		#cb2027;
$color-social-skype: 			#00acf4;
$color-social-snapchat: 		#fffc00;
$color-social-spotify: 			#2ebd59;
$color-social-telegram: 		#0088cc;
$color-social-tumblr: 			#32506d;
$color-social-twitter: 			#00aced;
$color-social-vimeo: 			#162221;
$color-social-whatsapp: 		#43d854;
$color-social-youtube: 			#bb0000;
