.carousel-post {
	margin-bottom: 100px;
}

.owl-gallery {
	height: 100%;
    overflow: hidden;

	.owl-wrapper-outer,
	.owl-wrapper,
	.owl-stage,
	.owl-stage-outer,
	.owl-item,
	.owl-gallery-item {
		height: 100%;
	}

	.owl-item {
		padding: 0;
	}

	.owl-pagination {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		/*padding: 145px 0;*/
		bottom: 5%;
		text-align: center;

		@media screen and (max-width: $screen-lg) {
			/*padding: 35px 0;*/
			top:85%;
		}

		.owl-page {
			display: inline-block;
			width: 20px;
			height: 20px;
			margin: 0 6px;
			background: $color-pallete-3;
			border: none;
			border-radius: 100%;
			cursor: pointer;

			&:hover, &:focus {
				background: darken($color-pallete-3, 15%);
			}

			&.active{
				background: $color-pallete-1;
			}
		}
	}

	@media screen and (max-width: $screen-lg) {
		position: static;
	}
}
