// CLEARFIX

@mixin clearfix() {
	*zoom: 1;

	&:before, &:after {
		content: "";
		display: table;
	}

	&:after {
		clear: both;
	}
}

@mixin column($count, $gap : 15px) {
	-webkit-column-count: $count;
	column-count: $count;
	-webkit-column-gap: $gap;
	column-gap: $gap;

	> * {
		-webkit-column-break-inside: avoid;
		column-break-inside: avoid;
	}
}

@mixin text-overflow($height, $lines) {
	display: block;
    display: -webkit-box;
    height: $height;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: $lines;
}
