.card-post {
	position: relative;
	height: 335px;
	margin: 0 10px;
	border-radius: 4px;
	box-shadow: 0 0 16px -4px $color-dark;
	overflow: hidden;

	&-anchor {
		display: block;
		width: 100%;
		height: 100%;
		color: $color-light;
		transition: opacity 0.1s ease;

		&:hover, &:focus {
			opacity: 0.8;
		}
	}

	&-image {
		width: 100%;
		height: 100%;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	&-content {
		position: absolute;
		bottom: 5%;
		left: 5%;
		width: 90%;
		text-align: center;
	}

	&-meta {
		position: absolute;
		top: 5%;
		left: 5%;
		width: 90%;
		text-align: right;

		&-item {
			display: inline-block;

			+ li {
				margin-left: 8px;
			}

			i {
				color: $color-pallete-1;
			}
		}
	}

	&-title {
		margin-bottom: 10px;
		font-size: 24px;
		font-weight: 700;
		text-shadow: 0 0 12px $color-dark;
	}

	&-date {
		font-size: 18px;
		font-weight: 700;
		text-shadow: 0 0 12px $color-dark;
	}
}

.card-post-list {
	position: relative;
	margin-bottom: 40px;
	@include clearfix;

	$content: #{&}-content;
	$image: #{&}-image;

	&-anchor {
		position: relative;
		display: block;
		transition: opacity 0.1s ease;
		@include clearfix;

		&:hover, &:focus {
			opacity: 0.8;
		}
	}

	&-image {
		position: absolute;
		top: 0;
		width: 49%;
		height: 100%;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			border-radius: 4px;
		}

		@media screen and (max-width: $screen-md) {
			position: static;
			width: 100%;
			height: 300px;
		}
	}

	&-content {
		box-sizing: border-box;
		width: 49%;
		padding: 85px 55px;
		background: $color-light;
		border-radius: 4px;
		text-align: center;

		p {
			line-height: 1.5;
		}

		@media screen and (max-width: $screen-md) {
			width: 100%;
		}
	}

	&-title {
		margin-bottom: 10px;
		font-size: 24px;
		font-weight: 700;
	}

	&-date {
		margin-bottom: 20px;
		font-size: 18px;
		font-weight: 700;
	}

	&-meta {
		margin-bottom: 10px;
		text-align: right;
		color: #b2b2b2;

		&-item {
			display: inline-block;

			+ li {
				margin-left: 8px;
			}

			i {
				color: $color-pallete-1;
			}
		}
	}

	&-excerpt {
		font-size: 18px;
	}

	&.content-left {
		#{$content} {
			float: left;
		}

		#{$image} {
			right: 0;
		}
	}

	&.content-right {
		#{$content} {
			float: right;
		}

		#{$image} {
			left: 0;
		}
	}
}
