.container {
	max-width: 1280px;
	width: 100%;
	margin: 0 auto;
	@include clearfix;
}

.page-content {
	max-width: 1200px;
	width: 100%;
	width: calc(100% - 160px);
	margin: 0 auto;
	padding: 40px 80px;
	font-size: 22px;
	text-align: center;
	/*font-weight: 700;*/

	p {
		float: none;
		margin-bottom: 20px;
	}

	@media screen and (max-width: $screen-md) {
		width: 100%;
		width: calc(100% - 80px);
		padding: 40px;
		font-size: 16px;
	}
}

.grid-half {
	position: relative;
	margin: 45px 0px 120px;

	&:last-of-type {
		margin-bottom: 60px;
	}

	@media screen and (max-width: $screen-md) {
		margin: 0 40px 60px;
	}

	&-content {
		box-sizing: border-box;
		position: relative;
		top: -20px;
		width: 50%;
		min-height: 330px;
		padding: 50px 75px;
		background: rgba(255, 255, 255, 0.95);
		border-radius: 12px; 
		line-height: 1.3;
		z-index: 10;

		$title: #{&}-title;

		#{$title} {
			/*margin-bottom: 24px;*/
			font-size: 42px;
			font-weight: 700;
			text-align: center;

			.append {
				font-size: 26px;
			}

			&::after {
				display: block;
				margin-top: 24px;
				content: url(../img/icons/wave.png);
			}

			@media screen and (max-width: $screen-md) {
				font-size: 28px;
			}
		}

		p {
			float: none;
			margin-bottom: 12px;
			font-size: 16px;

			&:last-of-type {
				margin-bottom: 0;
			}

			@media screen and (max-width: $screen-md) {
				font-size: 12px;
			}
		}

		ul {
			li {
				display: list-item;
				font-size: 22px;
				font-weight: 700;
				list-style-type: initial;
				list-style-position: inside;
			}
		}

		img {
			max-width: 100%;
		}

		&.content-left {
			float: left;

			#{$title} {
				&::after {
					transform: translateX(-725px);

					@media screen and (max-width: $screen-lg) {
						transform: translateX(-140px);
					}
				}
			}
		}

		&.content-right {
			float: right;
			text-align: right;

			#{$title} {
				&::after {
					transform: translateX(-35px);

					@media screen and (max-width: $screen-lg) {
						transform: translateX(-140px);
					}
				}
			}

			@media screen and (max-width: $screen-lg) {
				float: left;
				text-align: left;
			}
		}

		@media screen and (max-width: $screen-lg) {
			top: 0;
			width: 100%;
			border-radius: 0;
		}

		@media screen and (max-width: $screen-sm) {
			padding: 40px !important;
		}
	}

	&-carousel {
		position: absolute;
		top: 0;
		width: 51%;
		height: 91%;
		overflow: hidden;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}

		&.carousel-left {
			left: 0;
		}

		&.carousel-right {
			right: 0;
		}

		@media screen and (max-width: $screen-lg) {
			position: relative;
			width: 100%;
		}
	}
}

.grid-centered {
	max-width: 1020px;
	width: 100%;
	width: calc(100% - 160px);
	margin: 0 auto 120px;
	padding: 40px 80px;
	font-size: 22px;
	text-align: center;
	border-radius: 12px;
	background: $color-light;

	&-title {
		margin-bottom: 30px;
		font-weight: 700;
		font-size: 38px;

		&::after {
			display: block;
			margin: 24px 0 0 -126px;
			content: url(../img/icons/wave.png);
		}
	}

	@media screen and (max-width: $screen-md) {
		width: 100%;
		width: calc(100% - 80px);
		padding: 40px;
	}
}

.form-contact {
	margin-bottom: 80px;

	legend {
		margin-bottom: 60px;
		font-size: 32px;
		font-weight: 700;
	}

	.btn {
		margin: 0;
	}
}

.form-grid {
	width: 60%;
	margin: 0 auto;

	@media screen and (max-width: $screen-md) {
		width: 100%;
	}
}

.form-control {
	display: block;
	width: 100%;
	width: calc(100% - 24px);
	padding: 4px 12px;
	border-radius: 4px;
	color: $color-pallete-1;
	font-style: italic;
	font-weight: 700;
	border: 1px solid $color-pallete-1;

	&::placeholder {
		color: $color-pallete-1;
	}

	&:-ms-input-placeholder {
		color: $color-pallete-1;
	}

	&::-ms-input-placeholder {
		color: $color-pallete-1;
	}

	@at-root select#{&} {
		width: 100%;
    }
}

.btn {
	padding: 6px 24px;
	display: inline-block;
	font-family: inherit;
	font-size: 22px;
	font-style: italic;
	border: none;
	border-radius: 4px;
	cursor: pointer;

	&.btn-info {
		background: $color-pallete-1;
		color: $color-light;

		&:hover, &:focus {
			background: darken($color-pallete-1, 5%);
		}
	}
}

.float-none {
	float: none !important;
}

.no-padding {
	padding: 0 !important;
}

.owl-golf{

	.owl-dots {
		position: absolute;
		top: 450px;
		padding-left: 150px;
		text-align: left;

		@media screen and (max-width: $screen-lg) {
			top:85%;
		}

		.owl-dot {
			background: #7f7f7f;

			&:hover, &:focus {
				background: darken(#7f7f7f, 15%);
			}
		}
	}
}

.nav-blog {
	margin: 30px 0;

	&-menu {
		text-align: center;

		&-item {
			display: inline-block;

			> a {
				display: block;
				padding: 12px;
				font-size: 24px;
				font-weight: 700;
			}

			&.active {
				> a {
					font-size: 30px;
				}
			}

			@media screen and (max-width: $screen-md) {
				display: block;
				text-align: center;
			}
		}

		&::after {
			display: block;
			margin-top: 10px;
			content: url(../img/icons/wave.png);
		}
	}
}

.blog-post {
	margin-bottom: 80px;

	&-image {
		text-align: center;

		img {
			max-width: 100%;
			box-shadow: -2px 0 9px -4px $color-dark;
		}
	}

	&-content {
		position: relative;
		box-sizing: border-box;
		margin-top: -80px;
		padding: 60px 80px;
		background: rgba(255, 255, 255, 0.90);
		border-radius: 8px;
		box-shadow: 0 0 9px -4px $color-dark;

		@media screen and (max-width: $screen-sm) {
			padding: 30px 35px;
		}
	}

	&-title {
		margin-bottom: 10px;
		text-align: center;
		font-size: 42px;
		font-weight: 700;

		@media screen and (max-width: $screen-sm) {
			font-size: 32px;
		}
	}

	&-date {
		text-align: center;
		font-size: 24px;
		font-weight: 700;

		@media screen and (max-width: $screen-sm) {
			font-size: 20px;
		}
	}

	&-body {
		margin-bottom: 40px;
		padding-top: 40px;
		font-size: 21px;

		p{
			line-height: 1.5;
		}
	}

	&-meta {
		@include clearfix;

		&-left {
			float: left;
		}

		&-right {
			float: right;
		}

		&-social {
			display: inline-block;
			margin-left: 4px;
		}

		span {
			display: inline-block;
			margin-left: 4px;
			color: #a0a1a1;
		}
	}
}

#gastronomia.gastronomia-page {
	@include clearfix;

	.gastro-lista {
		height: auto;
	}
}

#receba-novidades {
	margin-top: 25px;
}

#banner-site .owl-carousel .owl-item {
	width: 100%;
}
