@media print {
	* {
		color: $color-text !important;
	}

	.header {
		display: none;
	}

	.footer {
		display: none;
	}

	.print-none {
		display: none !important;
	}
}
