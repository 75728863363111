.title-default {
	margin-bottom: 40px;
	font-size: 30px;
	font-weight: 700;
	color: $color-pallete-1;
	text-transform: uppercase;

	&-sm {
		text-transform: none;
	}

	b,
	strong {
		font-weight: 900;
	}

	@media screen and (max-width: $screen-sm) {
		font-size: 22px;
	}
}
